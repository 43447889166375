import { Scope } from '@ms/utilities-disposables/lib/Scope';
import type { IDisposable } from '@ms/utilities-disposables/lib/Disposable';
import { EventGroup } from '../utilities/EventGroup';
import type { ICommand } from '../interfaces/Commands';
import type {
  IActivateMessage,
  IInitializeMessage,
  IMessagePort,
  IMessenger,
  INotification,
  IIdentifyParentMessage
} from '../interfaces/Messenger';
import type { IResult, IQosError } from '../interfaces/Results';
import { Messenger } from './Messenger';

/**
 * Parameters to construct a messaging client, for communication with a host window.
 */
export interface IClientParams {
  /**
   * Unique identifier provided by the host to verify the source of messages.
   * Will be extracted from window.location.hash if not passed.
   */
  channelId?: string;

  /**
   * Origin of the host window.
   * Will be extracted from window.location.hash if not passed.
   */
  origin?: string;

  /**
   * The delay in milliseconds to wait for channel initialization.
   */
  initTimeoutMs?: number;

  /**
   * A default timeout for acknowledging commands, in milliseconds.
   */
  ackTimeoutMs?: number;

  /**
   * A default timeout for waiting for command results, in milliseconds.
   */
  resultTimeoutMs?: number;

  /**
   * The host window. Defaults to window.parent (if !== window) or window.opener.
   * if this is set explicitly to `null`, or if the host window cannot be identified,
   * then the client will wait for an `identify-parent` message to be sent.
   */
  host?: Pick<Window, 'postMessage'> | null;

  /**
   * If the client supports re-establishing a message channel with the host on reload
   */
  isRestartable?: boolean;

  /**
   * An override implementation of {@link IMessagePort} for when the standard postMessage channel is unavailable,
   * for example when hosted in a WebView. If this is passed, it will receive the 'initialize' message in lieu
   * of the host.
   */
  overridePort?: IMessagePort;

  /**
   * Object that will receive the 'identity-parent' message (if `host` is `null`). Defaults to `window`.
   */
  receiver?: EventTarget;

  /**
   * Handler for commands sent by the host. This function should check the `command` field to determine the appropriate action.
   * For unknown commands, the handler shall return `undefined`.
   */
  onCommand?(command: ICommand): Promise<IResult> | IResult | undefined;

  /**
   * Handler for notifications sent by the host.
   */
  onNotification?(notification: INotification): void;
}

/**
 * @internal
 */
export interface IClientDependencies {
  /**
   * Test hook to override the `MessageChannel` constructor.
   * @internal
   */
  channelType?: typeof MessageChannel;

  /**
   * Test hook to override the `EventGroup` constructor.
   * @internal
   */
  eventGroupType?: typeof EventGroup;

  /**
   * Test hook to override the `Messenger` constructor.
   * @internal
   */
  messengerType?: typeof Messenger;
}

/**
 * Populates the `channelId` and `origin` from window.location.hash
 * @param params - The params to the Messenger
 */
function getChannelParams(params: IClientParams): IClientParams {
  if (params.channelId && params.origin) {
    return params;
  }

  const hash = window.location.hash;
  if (!hash) {
    return params;
  }

  const fromQuery: Pick<IClientParams, 'channelId' | 'origin'> = {
    channelId: '',
    origin: ''
  };

  for (const elem of hash.slice(1).split('&')) {
    const [key, value] = elem.split('=', 3);
    if (fromQuery.hasOwnProperty(key)) {
      fromQuery[key as keyof typeof fromQuery] = decodeURIComponent(value);
    }
  }

  return {
    ...fromQuery,
    ...params
  };
}

function createClientRestartable(
  params: IClientParams,
  dependencies: IClientDependencies = {}
): IMessenger & IDisposable {
  const {
    channelId,
    origin = location.origin,
    host = (window.parent !== window && window.parent) || (window.opener as Window),
    overridePort,
    receiver = window
  } = getChannelParams(params);

  const {
    channelType = MessageChannel,
    eventGroupType = EventGroup,
    messengerType = Messenger
  } = dependencies;

  const scope = new Scope();
  const events = scope.attach(new eventGroupType(null));

  const init = (resolve: (port: IMessagePort) => void, reject: (error: IQosError) => void) => {
    // Create channel
    const { port1, port2 } = overridePort
      ? {
          port1: overridePort,
          port2: undefined
        }
      : new channelType();
    let portAlreadySent = false;

    scope.attach({
      dispose(): void {
        port1.close();
      }
    });

    let initializeSentFromIdentifyParent = false;
    const handleIdentifyParent = (message: MessageEvent): void => {
      // The 'identify-parent' message should be fired on an interval until an 'initialize'
      // message can be sent back to the source window.
      const data: IIdentifyParentMessage = message.data;

      if (
        initializeSentFromIdentifyParent ||
        !data ||
        typeof data !== 'object' ||
        data.type !== 'identify-parent' ||
        data.channelId !== channelId
      ) {
        // Ignore this message; it's meant for someone else.
      } else {
        events.off(receiver, 'message', handleIdentifyParent);
        const identifiedHost = message.source as Window;
        let newPort2: IMessagePort | undefined = port2;
        if (!overridePort && portAlreadySent) {
          const newChannel = new channelType();
          const newPort1 = newChannel.port1;
          newPort2 = newChannel.port2;
          events.on(newPort1, 'message', handleActivate.bind(null, newPort1));
          newPort1.start();
        }
        portAlreadySent = true;
        const freshInitializeMessage: Partial<IInitializeMessage> = {
          type: 'initialize',
          channelId: channelId,
          replyTo: newPort2
        };
        identifiedHost.postMessage(
          freshInitializeMessage,
          origin,
          newPort2 ? [newPort2 as Transferable] : undefined
        );
        initializeSentFromIdentifyParent = true;
      }
    };

    const handleActivate = (resolvedPort1: IMessagePort, message: MessageEvent): void => {
      const data: IActivateMessage = message.data;
      // MessageEvent on MessagePort does not include the origin, so can't validate
      if (!data || typeof data !== 'object' || data.type !== 'activate') {
        reject(new Error('Unexpected message during messenger initialization'));
      } else {
        events.dispose();
        resolve(resolvedPort1);
      }
      events.off(receiver, 'message', handleIdentifyParent);
    };

    events.on(port1, 'message', handleActivate.bind(null, port1));
    port1.start();

    const initializeMessage: Partial<IInitializeMessage> = {
      type: 'initialize',
      channelId: channelId,
      replyTo: port2
    };

    if (overridePort) {
      portAlreadySent = true;
      overridePort.postMessage(initializeMessage, [port2 as Transferable]);
    } else {
      if (host) {
        portAlreadySent = true;
        host.postMessage(initializeMessage, origin, [port2 as Transferable]);
        events.on(receiver, 'message', handleIdentifyParent);
      }
    }
  };

  return new messengerType({
    onCommand: params.onCommand,
    onNotification: params.onNotification,
    init: init,
    initResources: scope,
    initTimeoutMs: params.initTimeoutMs,
    ackTimeoutMs: params.ackTimeoutMs
  });
}

/**
 * Creates a messenger for communication with the host.
 * @param params - Configuration options for the messenger
 * @param dependencies - Optional overrides for external components, for testing
 */
export function createClient(
  params: IClientParams,
  dependencies: IClientDependencies = {}
): IMessenger & IDisposable {
  if (params.isRestartable) {
    return createClientRestartable(params, dependencies);
  }
  const {
    channelId,
    origin = location.origin,
    host = (window.parent !== window && window.parent) || (window.opener as Window),
    overridePort,
    receiver = window
  } = getChannelParams(params);

  const {
    channelType = MessageChannel,
    eventGroupType = EventGroup,
    messengerType = Messenger
  } = dependencies;

  const scope = new Scope();
  const events = scope.attach(new eventGroupType(null));

  const init = (resolve: (port: IMessagePort) => void, reject: (error: IQosError) => void) => {
    // Create channel
    const { port1, port2 } = overridePort
      ? {
          port1: overridePort,
          port2: undefined
        }
      : new channelType();

    scope.attach({
      dispose(): void {
        port1.close();
      }
    });

    const handleActivate = (message: MessageEvent): void => {
      const data: IActivateMessage = message.data;
      // MessageEvent on MessagePort does not include the origin, so can't validate
      if (!data || typeof data !== 'object' || data.type !== 'activate') {
        reject(new Error('Unexpected message during messenger initialization'));
      } else {
        events.dispose();
        resolve(port1);
      }
    };

    events.on(port1, 'message', handleActivate);
    port1.start();

    const initializeMessage: Partial<IInitializeMessage> = {
      type: 'initialize',
      channelId: channelId,
      replyTo: port2
    };

    if (overridePort) {
      overridePort.postMessage(initializeMessage, [port2 as Transferable]);
    } else {
      if (host) {
        host.postMessage(initializeMessage, origin, [port2 as Transferable]);
      } else {
        const handleIdentifyParent = (message: MessageEvent): void => {
          // The 'identify-parent' message should be fired on an interval until an 'initialize'
          // message can be sent back to the source window.
          const data: IIdentifyParentMessage = message.data;

          if (
            !data ||
            typeof data !== 'object' ||
            data.type !== 'identify-parent' ||
            data.channelId !== channelId
          ) {
            // Ignore this message; it's meant for someone else.
          } else {
            events.off(receiver, 'message', handleIdentifyParent);

            const identifiedHost = message.source as Window;

            identifiedHost.postMessage(initializeMessage, origin, [port2 as Transferable]);
          }
        };

        events.on(receiver, 'message', handleIdentifyParent);
      }
    }
  };

  return new messengerType({
    onCommand: params.onCommand,
    onNotification: params.onNotification,
    init: init,
    initResources: scope,
    initTimeoutMs: params.initTimeoutMs,
    ackTimeoutMs: params.ackTimeoutMs,
    resultTimeoutMs: params.resultTimeoutMs
  });
}
